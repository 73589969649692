<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div v-if="! show_ty">
      <form @submit="backoffice_courier_company_change_password">
        <div>
          <div>
            Enter New Password:
          </div>
          <div>
            <input v-model="password" type="password" v-bind:class="{input_error : password_input_error}" />
            <div class="input_error_txt" v-if="password_input_error">
              Password error
            </div>
          </div>
        </div>
        <div class="submit_cont">
          <input type="submit" value="Save" />
        </div>
      </form>
    </div>
    <div v-if="show_ty">
      הסיסמה שונתה בהצלחה<br />
      <!--<router-link to="/backoffice/courier_company_details">Back</router-link>-->
      <router-link :to="{path : '/backoffice/courier_company_details', query : {'id' : courier_company_id}}">חזור</router-link> | 
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase"
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficeCourierCompanyChangePassword',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop: false,
      courier_company_id : '0',
      password : '',
      show_ty : false,
      password_input_error : false
    }
  },
  async mounted() {
    this.courier_company_id = this.$route.query.id
  },
  methods : {
    async backoffice_courier_company_change_password(e) {
      e.preventDefault()
      let errs = 0
      this.password_input_error = false
      if (this.password == '') {
        errs++
        this.password_input_error = true
      }

      if (errs == 0) {
        const backendModel = new BackendModel()
        await backendModel.backendRequest("/Api/service/backoffice/backoffice_change_courier_company_password", {courier_company_id : this.courier_company_id, password : this.password})
        this.show_ty = true
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>
